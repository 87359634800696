<template>
  <div>
    <div class="vx-row mb-12"></div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.payment"
              v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"
            >
              <vs-td>
                <template v-if="selectedData.ID != tr.ID">
                  <vx-tooltip text="Apply Payment">
                    <vs-button
                      color="blue"
                      type="line"
                      @click="applyPayment(tr)"
                      icon-pack="feather"
                      icon="icon-file-text"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td> {{ tr.CustomerCode }} {{ tr.CustomerName }} </vs-td>
              <vs-td>
                Code : {{tr.Code}}
                <br>
                Reference Code : {{tr.ReferenceCode}}
                <br>
                Payment Type : {{typePayment[tr.Type]}}
                <br>
                {{ tr.PaymentMethod }} - {{ tr.PaymentName }}
                <br />
                {{ tr.PaymentAccountOwner }} {{ tr.PaymentAccountNumber }}
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.Amount) }}
              </vs-td>
              <vs-td>
                Created Date : {{ dateFormat(tr.CreatedAt) }}
                <br>
                Payment Date : {{ dateFormat(tr.DatePayment) }}
                <br>
                Posting Date : {{ dateFormat(tr.PostingDate) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <form-apply :payment="selectedData" @closeDetail="closeDetail" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { dataPayment } from "../../../../services/api/payment";
import formApply from "./apply-form.vue";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  components: {
    "form-apply": formApply,
  },
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit","Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      header: [
        {
          text: "Action",
          sortable: false,
        },
        {
          text: "Customer",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "created_at",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/3 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    applyPayment(tr) {
      this.detail = true;
      this.selectedData = tr;
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = dataPayment(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 30%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>