<template>
  <div>
    <vx-card title="Trade Payment">
      <vs-tabs :color="colorx" v-model="tabs">
        <vs-tab @click="colorx = 'danger'" label="Open">
          <div class="con-tab-ejemplo">
            <open />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'" label="Apply">
          <div class="con-tab-ejemplo">
            <apply />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'success'" label="Waiting Reconcile">
          <div class="con-tab-ejemplo">
            <reconcile />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'primary'" label="Done">
          <div class="con-tab-ejemplo">
            <done />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'success'" label="Waiting Reverse">
          <div class="con-tab-ejemplo">
            <waiting-reverse />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'success'" label="Reversed">
          <div class="con-tab-ejemplo">
            <reversed />
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import open from "./open.vue";
import apply from "./apply.vue";
import reconcile from "./reconcile.vue";
import done from "./done.vue";
import reversed from "./reversed.vue";
import waitingReverse from "./waiting_reverse.vue";

export default {
  components: {
    open,
    apply,
    reconcile,
    done,
    reversed,
    "waiting-reverse":waitingReverse
  },
  data: () => ({
    colorx: "danger",
    tabs: 0,
  }),
};
</script>
